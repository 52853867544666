.div-general-qrcode {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.card-general-qrcode {
  padding: 15px;
  width: 300px;
  position: relative;
  transition: 0.4s all linear;
  /* box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 0.75); */
}

.qrcode-scan {
  width: 100%;
}

.action-reader {
  border-radius: 10px;
  padding: 20px;
  color: white;
  width: 200px;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}

.action-reader:hover {
  color: white;
}

.open-reader {
  background-color: #0f20db;
}

.close-reader {
  background-color: #e00808;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

.a-loading {
  border: 6px solid #e5e5e5;
  border-top-color: #51d4db;
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 50%;
  animation: is-rotating 1s infinite;
}

.qrcode-scan {
  & section {
    border-radius: 50%;
  }
}

.div-option-qrcode{
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: white;
  margin: 5px 0;
  padding: 10px 0;
}

@media (max-width: 700px) {
  .card-general-qrcode {
    width: 95%;
  }
}
