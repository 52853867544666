.div-geral-qrcode-somoslev {
  width: 100%;
  min-height: 100vh;
  background: #9063cd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.card-qrcode {
  border-style: solid;
  border-width: 4px;
  border-color: white;
  border-radius: 15px;
  padding: 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reader-qrcode {
  color: white;
  background-color: #0060f5;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
}

.reader-qrcode-somoslev {
  color: white;
  background-color: #a17fd1;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
}

.div-audios {
  display: flex;
  flex-direction: column;
}

.audio {
  margin: 15px 0;
}

.lock-audio {
  padding: 10px;
  background-color: #0060f5;
  border-radius: 40px;
}

.close-scan {
  color: white;
  background-color: red;
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.close-scan:hover {
  color: white;
}

.div-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
}

.qrcode-msg {
  color: white;
  padding: 20px;
}

@media (max-width: 700px) {
  .card-qrcode {
    width: 95%;
  }

  .div-qrcode {
    width: 100%;
  }
}
